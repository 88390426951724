<template>
  <div class="page-bg">
    <div class="demo-main">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/demo' }">Demo</el-breadcrumb-item>
        <el-breadcrumb-item>Word speech assessment</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="title">Speech Assessment of English Word</div>
      <div class="sub-title">Please click the microphone and read aloud the following word.</div>
      <div v-if="startSoe" class="countdown">{{ second }}s</div>
      <div class="demo-container">
        <div class="ref-text-part">
          <div class="ref-text-content">
            <div class="ref-text">
              <span v-if="!hasResult&&!openEdit">{{ selectWordData[0] }}</span>
              <i v-if="!hasResult&&!openEdit" @click="openEdit=true" class="el-icon-edit"></i>
              <el-input v-if="!hasResult&&openEdit" v-model="selectWordData[0]" onkeyup="value=value.replace(/[^a-zA-Z]/g, '')" class="editInput" placeholder="write an english word"></el-input>
              <i v-if="!hasResult&&openEdit" @click="selectWordData[0]!==''?openEdit=false:openEdit=true" class="el-icon-check"></i>
              <span v-if="hasResult" :class="overall < 80?'non':'standard'">{{ selectWordData[0] }}</span>
            </div>
          </div>
        </div>
        <div class="btn-try-next-box">
          <el-button @click.stop="getRandomArrayElements($event, wordDataList, 1)" v-if="recorderStatus === 1" type="primary" plain>Try another one</el-button>
        </div>
        <div class="btn-recorder">
          <div @click="startEvaluation" v-if="recorderStatus === 1" class="recorder-box"><img src="~@/assets/images/soe_demo/demo/reg_start.png" alt=""></div>
          <div v-if="recorderStatus === 0" class="recorder-box recorder-box-loading">
            <ul class="loading-taste-line">
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
            </ul>
          </div>
          <div @click="stopEvaluation" v-if="recorderStatus === 2" class="recorder-box-stop">
            <ul class="start-taste-line">
              <li class="hr1"></li>
              <li class="hr2"></li>
              <li class="hr3"></li>
              <li class="hr4"></li>
              <li class="hr5"></li>
            </ul>
            <div class="recorder-stop">
              <span></span>
            </div>
            <ul class="start-taste-line">
              <li class="hr1"></li>
              <li class="hr2"></li>
              <li class="hr3"></li>
              <li class="hr4"></li>
              <li class="hr5"></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="setting">
        <el-form ref="form" label-width="" inline>
          <el-form-item label="Evaluation group">
            <el-radio v-model="group" label="">Universal</el-radio>
            <el-radio v-model="group" label="_kids">Kids</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <el-divider v-if="hasResult"></el-divider>
      <div v-if="hasResult" class="result-detail">
        <div class="result-overall">
          <div class="text">Overall</div>
          <div class="play-replay">
            <span>{{ overall }}</span>
            <img v-if="!ifplayAudio_replay" @click="playAudio_replay" src="~@/assets/images/soe_demo/demo/play_audio.png" alt="">
            <img v-if="ifplayAudio_replay" @click="stopAudio_replay" src="~@/assets/images/soe_demo/demo/stop_audio.png" alt="">
            <audio ref="audioElement_replay" @ended="onAudioEnded_replay" :src="audioUrl"></audio>
          </div>
        </div>
        <div class="result-overall result-stress">
          <div class="text">Stress Mastery</div>
          <div class="play-replay">
            <span>[{{ syllable }}]</span>
          </div>
        </div>
        <div class="result-part result-phoneme">
          <div class="part-title">Phoneme-level Results</div>
          <el-table class="phonemeTable" :data="phonemes" border>
            <el-table-column
                label-class-name="phonemeColumn"
                class-name="phonemeColumnList"
                align="center"
                prop="phoneme"
                label="phoneme">
            </el-table-column>
            <el-table-column
                label-class-name="phonemeColumn"
                class-name="phonemeColumnList"
                align="center"
                prop="score"
                label="score">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Phoneme',
  data () {
    return {
      soe: null,

      startSoe: false,
      second: 15,
      loanTime: '',

      openEdit: false,
      phonemeList: [
        {zy: 'iy', dj: 'i:', kk: 'i'},
        {zy: 'ih', dj: 'ɪ', kk: 'ɪ'},
        {zy: 'eh', dj: 'e', kk: 'ɛ'},
        {zy: 'ae', dj: 'æ', kk: 'æ'},
        {zy: 'aa', dj: 'ɑ:', kk: 'ɑ'},
        {zy: 'ao', dj: 'ɔ:', kk: 'ɔ'},
        {zy: 'oo', dj: 'ɒ', kk: '-'},
        {zy: 'uw', dj: 'u:', kk: 'u'},
        {zy: 'uh', dj: 'ʊ', kk: 'ʊ'},
        {zy: 'ah', dj: 'ʌ', kk: 'ʌ'},
        {zy: 'ax', dj: 'ə', kk: 'ə'},
        {zy: 'ey', dj: 'eɪ', kk: 'e'},
        {zy: 'ay', dj: 'aɪ', kk: 'aɪ'},
        {zy: 'oy', dj: 'ɔɪ', kk: 'ɔɪ'},
        {zy: 'ir', dj: 'ɪə', kk: 'ɪr'},
        {zy: 'er', dj: 'ɜ:', kk: '-'},
        {zy: 'ar', dj: 'eə', kk: 'ɛr'},
        {zy: 'ur', dj: 'ʊə', kk: 'ʊr'},
        {zy: 'ow', dj: 'əʊ', kk: 'o'},
        {zy: 'aw', dj: 'aʊ', kk: 'ɑʊ'},
        {zy: 'b', dj: 'b', kk: 'b'},
        {zy: 'd', dj: 'd', kk: 'd'},
        {zy: 'g', dj: 'g', kk: 'g'},
        {zy: 'v', dj: 'v', kk: 'v'},
        {zy: 'z', dj: 'z', kk: 'z'},
        {zy: 'dh', dj: 'ð', kk: 'ð'},
        {zy: 'zh', dj: 'ʒ', kk: 'ʒ'},
        {zy: 'jh', dj: 'dʒ', kk: 'dʒ'},
        {zy: 'm', dj: 'm', kk: 'm'},
        {zy: 'n', dj: 'n', kk: 'n'},
        {zy: 'ng', dj: 'ŋ', kk: 'ŋ'},
        {zy: 'l', dj: 'l', kk: 'l'},
        {zy: 'r', dj: 'r', kk: 'r'},
        {zy: 'y', dj: 'j', kk: 'j'},
        {zy: 'w', dj: 'w', kk: 'w'},
        {zy: 'p', dj: 'p', kk: 'p'},
        {zy: 't', dj: 't', kk: 't'},
        {zy: 'k', dj: 'k', kk: 'k'},
        {zy: 'f', dj: 'f', kk: 'f'},
        {zy: 's', dj: 's', kk: 's'},
        {zy: 'th', dj: 'θ', kk: 'θ'},
        {zy: 'sh', dj: 'ʃ', kk: 'ʃ'},
        {zy: 'ch', dj: 'tʃ', kk: 'tʃ'},
        {zy: 'hh', dj: 'h', kk: 'h'},
        {zy: 'tr', dj: 'tr', kk: '-'},
        {zy: 'ts', dj: 'ts', kk: '-'},
        {zy: 'dr', dj: 'dr', kk: '-'},
        {zy: 'dz', dj: 'dz', kk: '-'}
      ],
      wordDataList: [
        "weather",
        "address",
        "blackboard",
        "challenge",
        "dictionary",
        "elephant",
        "finance",
        "government",
        "harvest",
        "impression",
        "journey",
        "knowledge",
        "library",
        "microphone",
        "neighbour",
        "overcome",
        "passport",
        "question",
        "revolution",
        "similar",
        "technology",
      ],
      selectWordData: ["weather"],

      group: '',

      ifplayAudio_replay: false,

      recorderStatus: 1,

      hasResult: false,
      overall: '',
      syllable: '',
      phonemes: [],
      audioUrl: '',
    }
  },
  mounted() {
    this.soe = new this.$SoeEngine({
      preService: this.$globalParam.preService,
      log: this.$globalParam.log,
      appId: this.$globalParam.appId,
      appSecret: this.$globalParam.appSecret,
      coreType: {
        langType: 'en-US',
        format: 'mp3', // 实时录音时可不传值；上传文件时传音频格式，支持 mp3、wav、pcm
        sampleRate: 16000, // 音频采样率（Hz）：当前仅支持 16000
        looseness: 4, // 评分宽松度，范围：0-9，数值越大越宽松
        connectTimeout: 15, // 连接超时时间（秒），范围：5-60
        responseTimeout: 15, // 响应超时时间（秒），范围：5-60
        scale: 100, // 评分分制，范围：1-100
        ratio: 1, // 评分调节系数，范围：0.8-1.5
        userId: '',
        realtime: false,
        audioUrl: true,
      },
      params: {
        mode: "", // 评测模式：基础题型 word/sentence/chapter  高阶题型 qa/topic/retell
        // 基础题型（词、句、篇章）
        refText: "", // 测评对照阅读的文本：
        // 高阶题型（问答题）
        stem: { // 题干
          para: "", // 段落内容
          question: "", // 提问内容
        },
        distractor: [],
        // 高阶题型（问答题）&& 高阶题型（看图说话/口语作文）&& 高阶题型（复述）
        answer: [],
        keyword: [],
      },
      micAllowCallback: () => {
        console.log('=====The microphone has been allowed.=====');
      },
      micForbidCallback: (msg) => {
        console.error('====='+msg+'=====');
        this.recorderStatus = 1
        this.startSoe = false
        this.$Toast(msg)
      },
      engineFirstInitDone: () => {
        console.log('=====Initialization successful.=====');
      },
      engineFirstInitFail: (status,msg) => {
        console.error('====='+status+':'+msg+'=====');
        this.recorderStatus = 1
        this.$Toast(status+':'+msg)
      },
      getStarted: () => {
        this.recorderStatus = 2
        this.loanTime = setInterval(() => {
          this.second--
          if (this.second <= 0) {
            clearInterval(this.loanTime)
            this.loanTime = ''
            this.second = 15
            this.stopEvaluation()
          }
        }, 1000)
      },
      getRealtimeResult: (msg) => {
        console.log(msg);
      },
      engineBackResultDone: (msg) => {
        this.recorderStatus = 1
        console.log(msg);
        this.getResult(msg)
      },
      engineBackResultWarning: (status,msg) => {
        console.log(status,msg)
      },
      engineBackResultError: (status,msg) => {
        console.log(status,msg)
        this.recorderStatus = 1
        this.startSoe = false
        this.$Toast(status+':'+msg)
      },
      playAudioComplete: () => {
        console.log('Playback complete.');
      },
      playAudioError: () => {
        this.recorderStatus = 1
        this.$Toast("Playback error")
      },
      micVolumeCallback: (data) => {
        console.log('Recording volume level is:' + data);
      },
      noNetwork: () => {
        this.recorderStatus = 1
        this.$Toast("No network available")
      },
      recorderBlob: (blob) => {
        console.log(blob)
      },
    })
  },
  methods: {
    // 切换内容
    getRandomArrayElements(e, arr, count) {
      e.preventDefault();
      this.hasResult = false

      let shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      if (shuffled.slice(min)[0] === this.selectWordData[0]) {
        this.getRandomArrayElements(arr, count)
        return
      }
      this.selectWordData = shuffled.slice(min)
    },

    // 开始评测
    startEvaluation() {
      if (this.selectWordData[0] === '') {
        return false
      }
      this.recorderStatus = 0
      this.soe.params.mode = "word"+this.group;
      this.soe.params.refText = this.selectWordData[0];
      let params = this.soe.params;
      this.soe.startRecord(params);
      this.startSoe = true
    },
    // 结束评测
    stopEvaluation() {
      clearInterval(this.loanTime)
      this.loanTime = ''
      this.second = 15
      this.soe.stopRecord();
      this.startSoe = false
    },
    // 处理评测结果
    getResult(result) {
      let phonemeList = this.phonemeList
      if (result.wordInfo[0].syllables.length !== 0) {
        let syllableNew = ''
        let phonemesNew = []
        for (let i=0;i<result.wordInfo[0].syllables.length;i++) {
          if (result.wordInfo[0].syllables[i].stressRef === 1) {
            let newsyllable = ''
            for (let m=0;m<result.wordInfo[0].syllables[i].syllable.split('_').length;m++) {
              for (let n=0;n<phonemeList.length;n++) {
                if (result.wordInfo[0].syllables[i].syllable.split('_')[m] === phonemeList[n].zy) {
                  newsyllable += phonemeList[n].dj+'_'
                }
              }
            }
            syllableNew+=newsyllable.slice(0, -1)+';'
          }
          for (let j=0;j<result.wordInfo[0].syllables[i].phonemes.length;j++) {
            for (let k=0;k<phonemeList.length;k++) {
              if (result.wordInfo[0].syllables[i].phonemes[j].phoneme === phonemeList[k].zy) {
                let newphoneme = {
                  phoneme: phonemeList[k].dj,
                  score: (result.wordInfo[0].syllables[i].phonemes[j].score).toFixed(0)
                }
                phonemesNew.push(newphoneme)
              }
            }
          }
        }
        let hypothesisData
        if (result.hypothesis === ''||result.hypothesis === '***') {
          hypothesisData = result.params.request.params.refText
        } else {
          hypothesisData = result.hypothesis
        }
        this.overall = (result.wordInfo[0].score).toFixed(0)
        this.hypothesis = hypothesisData
        this.syllable = syllableNew.slice(0, -1)
        this.phonemes = phonemesNew
      } else {
        let hypothesisData
        if (result.hypothesis === ''||result.hypothesis === '***') {
          hypothesisData = result.params.request.params.refText
        } else {
          hypothesisData = result.hypothesis
        }
        this.overall = (result.wordInfo[0].score).toFixed(0)
        this.hypothesis = hypothesisData
      }

      this.audioUrl = result.audioUrl
      this.hasResult = true
    },
    playAudio_replay() {
      const audioElement_replay = this.$refs.audioElement_replay;
      audioElement_replay.play();
      this.ifplayAudio_replay = true
    },
    stopAudio_replay() {
      const audioElement_replay = this.$refs.audioElement_replay;
      audioElement_replay.pause();
      audioElement_replay.currentTime = 0;
      this.ifplayAudio_replay = false
    },
    onAudioEnded_replay() {
      this.ifplayAudio_replay = false
    },
  }
}
</script>

<style scoped lang="less">
.page-bg {
  display: flex;
  padding: 30px 0;
}
.demo-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 85%;
  padding: 60px 10px 30px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0 15px 0 hsla(0,0%,79.6%,.5);
  border-radius: 10px;
  .breadcrumb {
    position: absolute;
    left: 10px;
    top: 10px;
    margin-bottom: 30px;
  }
  .title {
    line-height: 30px;
    color: #000;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
  }
  .sub-title {
    line-height: 28px;
    margin: 15px auto 0;
    color: #333;
    text-align: center;

    font-size: 16px;
  }
  .countdown {
    position: absolute;
    right: 10px;
    top: 30px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border-radius: 100%;
    background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
    color: #fff;
  }
  .demo-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 0 0;
    .ref-text-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 58px;
      margin: 0 auto;
      .ref-text {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 26px;
        color: #333;
        .el-icon-edit {
          position: absolute;
          right: -30px;
          top: 5px;
          font-size: 24px;
          color: #2D91FA;
          cursor: pointer;
        }
        .el-icon-check {
          color: #2D91FA;
          cursor: pointer;
        }
        .editInput {
          /deep/ .el-input__inner {
            width: 100%;
            font-size: 20px;
            border-left: none;
            border-right: none;
            border-top: none;
            border-radius: inherit;
            text-align: center;
          }
        }
      }
    }
    .btn-try-next-box {
      margin-top: 30px;
      /deep/ .el-button--primary.is-plain {
        background: transparent;
        border-color: #2876F1;
        color: #2876F1;
        font-weight: 500;
      }
    }
    .btn-recorder {
      position: relative;
      height: 80px;
      margin-top: 23px;
      cursor: pointer;
      .recorder-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
        box-shadow: 0 2px 6px 0 rgba(0,110,254,0.4);
        cursor: pointer;
        img {
          width: 50%;
        }
      }
      .recorder-box-loading {
        background: #DAEAFF;
        .loading-taste-line {
          position: absolute;
          width: 40px;
          height: 40px;
        }
        .item{
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          animation: xuanzhuan 4s linear infinite;
        }
        .item:nth-child(1){
          animation-delay: 0.15s;
        }
        .item:nth-child(2){
          animation-delay: 0.3s;
        }
        .item:nth-child(3){
          animation-delay: 0.45s;
        }
        .item:nth-child(4){
          animation-delay: 0.6s;
        }
        .item:nth-child(5){
          animation-delay: 0.75s;
        }
        .item::after{
          content:'';
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: royalblue;
        }
        @keyframes xuanzhuan{
          75%{
            transform: rotate(650deg);
          }
          79%{
            transform: rotate(720deg);
            opacity: 1;
          }
          80%{
            transform: rotate(720deg);
            opacity: 0;
          }
          100%{
            transform: rotate(810deg);
            opacity: 0;
          }
        }
      }
      .recorder-box-stop {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 80px;
        .recorder-stop {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          margin: 0 10px;
          border-radius: 100%;
          background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
          box-shadow: 0 2px 6px 0 rgba(0,110,254,0.4);
          cursor: pointer;
          span {
            width: 22px;
            height: 22px;
            background: #fff;
            border-radius: 3px;
          }
        }
        .start-taste-line {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .start-taste-line li {
          background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
          width: 4px;
          height: 6px;
          margin: 0 2px;
          display: inline-block;
          border: none;
          border-radius: 1px;
        }
        li {
          animation: note 0.3s ease-in-out;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }
        .hr1 {
          animation-delay: -1s;
        }
        .hr2 {
          animation-delay: -0.9s;
        }
        .hr3 {
          animation-delay: -0.8s;
        }
        .hr4 {
          animation-delay: -0.9s;
        }
        .hr5 {
          animation-delay: -1s;
        }
        @keyframes note {
          from {
            transform: scaleY(1);
          }
          to {
            transform: scaleY(5);
          }
        }
      }
    }
  }
  .setting {
    width: 100%;
    .el-form-item {
      margin-bottom: 0;
    }
    /deep/ .el-form-item__label {
      font-weight: 500;
      font-size: 14px;
    }
    /deep/ .el-form-item__content {
      height: 40px;
      line-height: 48px;
    }
    /deep/ .el-radio {
      display: inline-flex;
      margin-right: 10px;
    }
    /deep/ .el-radio__label {
      display: inline-flex;
      padding-left: 5px;
    }
    /deep/ .el-radio__input {
      height: 14px;
    }
  }
  .result-detail {
    width: 100%;
    .result-overall {
      display: flex;
      align-items: center;
      flex-direction: column;
      .text {
        font-size: 20px;
        color: #1377e1;
        line-height: 25px;
      }
      .play-replay {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 50px;
        margin-top: 10px;
        background: #F5F9FF;
        border-radius: 36px;
        span {
          height: 30px;
          line-height: 25px;
          margin-right: 10px;
          font-weight: 500;
          font-size: 36px;
          color: #1377e1;
        }
        img {
          width: 25px;
          cursor: pointer;
        }
      }
    }
    .result-stress {
      margin-top: 10px;
      .play-replay {
        width: auto;
        height: 40px;
        padding: 0 50px;
        span {
          height: auto;
          line-height: normal;
          margin: 0;
          font-size: 16px;
        }
      }
    }
    .result-phoneme {
      .part-title {
        padding: 20px 0;
        text-align: center;
        font-weight: 500;
        font-size: 22px;
        color: #000;
      }
      .phonemeTable {
        width: 95%;
        margin: 0 auto;
        border: 1px solid #d2e8ff;
        border-radius: 10px;
        overflow: hidden;
        /deep/ .phonemeColumnList {
          line-height: 30px;
          font-size: 18px;
          border-color: #d2e8ff;
        }
        /deep/ .phonemeColumn {
          line-height: 30px;
          font-size: 20px;
          color: #000;
          background: #F5F9FF;
          border-color: #d2e8ff;
        }
      }
    }
  }
}
</style>